import React, { Component } from "react";
import { Card, CardBody, Container, Row, Col, TabContent, TabPane, Label, FormGroup,Collapse, Alert } from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logo from "../../assets/images/site-logo.png";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, FieldArray, ErrorMessage  } from "formik";
import GoogleAutocomplete from 'react-google-autocomplete';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import uuidValidate from 'uuid-validate'; 
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from "axios";
import { BlobServiceClient } from '@azure/storage-blob';
class ProjectWorksheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      files: [],
      col0:true,
      WorkheetStatus:'',
      projectData:'',
      formVisible: true,
      thankYouMessage: '',
      projectdata:'',
      accordionState: { col0: true },
    };

    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
   
  }
  toggleAccordion = (index) => {
    this.setState((prevState) => ({
      accordionState: {
        ...prevState.accordionState,
        [`col${index}`]: !prevState.accordionState[`col${index}`],
      },
    }));
  };
  t_col1() {
    this.setState({
      col0: !this.state.col0,
      col2: false,
     
    });
  }

  t_col2() {
    this.setState({
      col0: false,
      col1: !this.state.col1,
     
    });
  }

  
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  addDocument = (index) => {

    this.setState((prevState) => {
      const updatedPurchasers = [...prevState.purchasers];
      updatedPurchasers[index].documents.push({ fileName: '', file: '' });
      return { purchasers: updatedPurchasers };
    });
  };

  removeDocument = (purchaserIndex, docIndex) => {
    // Remove a document for the purchaser at given index
    this.setState((prevState) => {
      const updatedPurchasers = [...prevState.purchasers];
      updatedPurchasers[purchaserIndex].documents.splice(docIndex, 1);
      return { purchasers: updatedPurchasers };
    });
  };
 
  async handleFileChange(event, index, docIndex, setFieldValue) {
    const file = event.currentTarget.files[0];
    if (file) {
      const sasToken = "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-06-22T02:18:02Z&st=2024-06-21T18:18:02Z&spr=https,http&sig=Srg7JmJ7EfJQWUNadO188IgXNeCFEzSmQQmctuyjGxA%3D";
      const containerName = "images"; 
      const storageAccountName = "realtyspace";
      const fileName = `${Date.now()}_${file.name}`;
      const blobServiceClient = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
      );
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlockBlobClient(fileName);

      try {
        await blobClient.uploadBrowserData(file, {
          blobHTTPHeaders: { blobContentType: file.type }
        });
        const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`;
        //const id = response.data.projectProspectAgentDocument.id;
          
        setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentUrl`, fileUrl);
        setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.projectProspectAgentDocumentId`, 1);
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to upload file.');
      }
    }
  }
  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    console.log(params.worksheetId);
   
    const leadId = params.worksheetId;
   
    axios.get(`https://rscoreapi.azurewebsites.net/api/ProjectCampaignLead/fullbyid/${leadId}`)
    .then(res => {
      const WorkheetStatus = res.data;
      this.setState({ WorkheetStatus }, () => {
        const campaignProjectId = this.state.WorkheetStatus.campaignProjectId;
        axios.get(`https://rscoreapi.azurewebsites.net/api/ProjectExt/uid?projectid=${campaignProjectId}`)
        .then(response => {
          const projectdata = response.data;
          this.setState({ projectdata });
          //console.log(projectdata); // Log the second API response
        })
        .catch(error => {
          console.error("There was an error fetching the second data!", error);
        });
    
      });
    })
    .catch(error => {
      // Handle error
      console.error("There was an error fetching the data!", error);
    });
  }
  removeEmptyPurchasers = (data) => {
    return data.map(purchaser => {
      const filteredDocuments = purchaser.createProjectLeadWorkSheetPurchaserDocumentList.filter(doc => doc.documentTitle.trim() !== '' || doc.documentUrl.trim() !== '');
      return {
        ...purchaser,
        createProjectLeadWorkSheetPurchaserDocumentList: filteredDocuments.length > 0 ? filteredDocuments : []
      };
    }).filter(item => item.firstName.trim() !== '' || item.lastName.trim() !== '');
  }

  handleSubmit = (values, { setSubmitting }) => {
    const cleanedData = {
      ...values,
      createProjectLeadWorkSheetPurchaserList: this.removeEmptyPurchasers(values.createProjectLeadWorkSheetPurchaserList),
    
    };

    const headers = {
      'Content-Type': 'application/json',
      //'Authorization': str
    };
   
    toast.loading("Please wait...");
    axios.put('https://rscoreapi.azurewebsites.net/api/ProjectLeadWorkSheet', cleanedData, { headers })
      .then(response => {
        toast.dismiss();
        if (response.data.success) {
          toast.success("Worksheet Form Saved");
          const { email, mobile } = response.data.projectLeadWorkSheet.currentAgent.agentContact.cContact;
         
          const { leadId} = this.props.match.params;
          this.setState({
            formVisible: false,
            thankYouMessage:`We will contact you shortly to go over the next steps. If you have questions feel free to call at ${this.state.WorkheetStatus?.agent?.firstName+" "+this.state.WorkheetStatus?.agent?.lastName	} or email at ${this.stateWorkheetStatus?.agent?.primaryEmail}.`,
          });
        } else {
          toast.warn(response.validationErrors[0]);
        }
      })
      .catch(error => {
        toast.dismiss();
        console.error('Error:', error);
      });
    setSubmitting(false);
  }

handlePlaceSelected = (address, index, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;
        
        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };

        const streetNumber = getComponent(['street_number']);
        const route = getComponent(['route']);
        const city = getComponent(['locality', 'political']);
        const province = getComponent(['administrative_area_level_1', 'political']);
        const postalCode = getComponent(['postal_code']);
        const country = getComponent(['country', 'political']);

        const modifiedAddress = `${streetNumber} ${route}`;
        console.log(modifiedAddress)
        setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.address`, modifiedAddress);

        //setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.address`, place.formatted_address);
        setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.city`, city);
        setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.province`, province);
        setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.postalCode`, postalCode);
        setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.country`, country);
      })
      .catch(error => console.error('Error', error));
  };
  render() {
    const { WorkheetStatus, projectdata } = this.state;
    const { formVisible, thankYouMessage } = this.state;
    //console.log(projectdata);
    return (
     
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <MetaTags>
            <title>Worksheet | Realty Space</title>
          </MetaTags>
      {this.state.projectdata.isActive ? ( 
        <>
        <div className="headrtop">
          <div className="toppart">
            <Row>
              <Col sm="3">
                <div className="d-flex flex-wrap gap-2">
                  <img src={logo} alt="" height="70px" />
                </div>
              </Col>
             
              <Col xl="3">
                <h3 className="text-center mt-3">
                 
                </h3>
              </Col>
            
              <Col sm="6">
                <Row>
                  <Col sm="4">
                    <h4 className="agentInfo"><strong>Agent</strong><br />
                    <span>{WorkheetStatus?.agent?.firstName+" "+WorkheetStatus?.agent?.lastName	}</span>
                    </h4>
                  </Col>
                  <Col sm="4">
                  <h4 className="agentInfo"><strong>Phone</strong><br />
                    <span>{WorkheetStatus?.agent?.agentContact?.cContact?.phone }</span>
                    </h4>
                  </Col>
                  <Col sm="4">
                  <h4 className="agentInfo"><strong>Email</strong><br />
                    <span>{WorkheetStatus?.agent?.primaryEmail	 }</span>
                    </h4>
                  </Col>
                </Row>


              </Col>
            </Row>
          </div>
        </div>
        <div className="page-content" style={{paddingTop:40}}>

          <Container fluid>
           
            <TabContent
              activeTab={this.state.activeTab}
              className="p-3 text-muted"
            >
              <TabPane tabId="1">
              {this.state.projectdata && (
                <>
          
                <Row>
                  <Col xl="3"></Col>
                  <Col sm="6" className="text-center ">
                      <h2 style={{marginBottom:20}}>Worksheet Submission for {this.state.WorkheetStatus.firstName+" "+this.state.WorkheetStatus.lastName	}</h2>
                      { this.state.projectdata.projectLogoLink ? <img style={{width:200}} className="img-fluid" src={this.state.projectdata.projectLogoLink	 } /> : '' }
                 
                    <h2 style={{marginTop:20, marginBottom:10}}>{this.state.projectdata.projectName	}</h2>
                    <h4 style={{marginBottom:20, marginTop:0, paddingTop:0}}>{this.state.projectdata?.projectSiteAddress?.fullAddress	}</h4>
                  </Col>
                </Row>
                </>
              )}
                <Row>
                  <Col xl="3"></Col>
             {WorkheetStatus.isWrokSheetFilled ? (
                   <Col sm="6" className="text-center ">
                   <h4 className="text-danger">Thank you </h4>
                   <h5>Form Already Filled</h5>
                  
                 </Col>
              ) : ( 
                  <Col xl="6">
                  {formVisible ? (
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        projectLeadWorkSheetId: this.props.match.params.worksheetId,
                        projectCampaignLeadGUId:this.props.match.params.worksheetId,
                        projectCampaignLeadId:0,
                        campaignProjectId:0,
                        noOfOptions:0,
                        isActive:true,
                        worksheetNotes:'',
                        agentId:0,
                        isParkingRequired: false,
                        isLockerRequired: false,
                        createProjectLeadWorkSheetOptionList: Array.from({ length: (this.state.projectdata.noOfUnitforWorkSheet ? this.state.projectdata.noOfUnitforWorkSheet : 2 ) }, () => ({ projectLeadWorkSheetId:0 , optionNumber: '', optionType: '', optionNotes: '', agentId: 0, addedBy:'', })),
                       
                        createProjectLeadWorkSheetPurchaserList: Array.from({ length: 2 }, () => ({
                          projectLeadWorkSheetId:0,
                          firstName: '',
                          lastName: '',
                          address: '',
                          city: '',
                          province: '',
                          postalCode: '',
                          country: '',
                          homePhone: '',
                          cellPhone: '',
                          email: '',
                          //birthDate: new Date(),
                          driversLicense: '',
                          occupation: '',
                          createProjectLeadWorkSheetPurchaserDocumentList: [{ projectLeadWorkSheetId:0, projectLeadWorkSheetPurchaserId:0, projectProspectAgentDocumentId:0,  documentTitle: '', documentUrl:'' }] 
                        })),
                      }}
                      validationSchema={Yup.object().shape({
                        createProjectLeadWorkSheetOptionList: Yup.array().of(
                          Yup.object().shape({
                            optionNumber: Yup.string().required('Model Name is required'),
                            optionType: Yup.string().required('Bedroom Type is required'),
                            //optionNotes: Yup.string().required('Bedroom Type is required')
                          })
                        ),
                        createProjectLeadWorkSheetPurchaserList: Yup.array().of(
                          Yup.object().shape({
                            firstName: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            lastName: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            email: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            address: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            address: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            city: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            province: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            postalCode: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            country: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            homePhone: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            cellPhone: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            birthDate: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            driversLicense: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            }),
                            occupation: Yup.string().test('is-required', 'This is required', function (value) {
                              return this.options.index !== 0 || (value && value.trim().length > 0);
                            })
                          })
                        )
                      })}
                      onSubmit={this.handleSubmit}
                      >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form className="needs-validation">
                          <Card className="newCard">
                            <CardBody>
                              <h5 className="card-title text-upper"> please indicate your unit choices</h5>
                            <FieldArray name="createProjectLeadWorkSheetOptionList">
            {({ insert, remove, push }) => (
              <>
                {values.createProjectLeadWorkSheetOptionList.map((LeadWorkSheetOptionList, index) => (
                  <Row key={index}>
                    <h4>Choice {index + 1}</h4>
                    <Col sm="4">
                      <FormGroup className="mb-3">
                      <Label htmlFor={`createProjectLeadWorkSheetOptionList.${index}.optionNumber`}>
                          {this.props.t("Model Name")}
                        </Label>
                        <Field
                          name={`createProjectLeadWorkSheetOptionList.${index}.optionNumber`}
                          type="text"
                          className={
                            'form-control' +
                            (errors.createProjectLeadWorkSheetOptionList?.[index]?.optionNumber && touched.createProjectLeadWorkSheetOptionList?.[index]?.optionNumber
                              ? ' is-invalid'
                              : '')
                          }
                          placeholder=""
                          id={`optionNumber${index + 1}`}
                        />
                        <ErrorMessage
                          name={`createProjectLeadWorkSheetOptionList.${index}.optionNumber`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup className="mb-3">
                      <Label htmlFor={`createProjectLeadWorkSheetOptionList.${index}.optionType`}>
                          {this.props.t("Bedroom Type")}
                        </Label>
                        <Field
                          name={`createProjectLeadWorkSheetOptionList.${index}.optionType`}
                          type="text"
                          className={
                            'form-control' +
                            (errors.createProjectLeadWorkSheetOptionList?.[index]?.optionType && touched.createProjectLeadWorkSheetOptionList?.[index]?.optionType ? ' is-invalid' : '')
                          }
                          placeholder=""
                          id={`optionType${index + 1}`}
                        />
                        <ErrorMessage name={`createProjectLeadWorkSheetOptionList.${index}.optionType`} component="div" className="invalid-feedback" />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup className="mb-3">
                      <Label htmlFor={`createProjectLeadWorkSheetOptionList.${index}.optionNotes`}>
                          {this.props.t("Notes")}
                        </Label>
                        <Field
                          name={`createProjectLeadWorkSheetOptionList.${index}.optionNotes`}
                          type="text"
                          className={
                            'form-control' +
                            (errors.createProjectLeadWorkSheetOptionList?.[index]?.optionNotes && touched.createProjectLeadWorkSheetOptionList?.[index]?.optionNotes ? ' is-invalid' : '')
                          }
                          placeholder=""
                          id={`optionNotes${index + 1}`}
                        />
                        <ErrorMessage name={`createProjectLeadWorkSheetOptionList.${index}.optionNotes`} component="div" className="invalid-feedback" />
                      </FormGroup>
                    </Col>
                  </Row>
                ))}
              </>
            )}
                            </FieldArray>
          </CardBody>
          </Card>
          <Card className="newCard">
          <CardBody>
          <Row>
                                    
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label className="form-check-label me-4 mt-c" htmlFor="isParkingRequired">
                                          <span className="h4">Parking</span>
                                        </Label>
                                        <Field
                                          type="checkbox"
                                          id="isParkingRequired"
                                          name="isParkingRequired"
                                          className="form-check-input mt-2"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label className="form-check-label me-4 mt-c" htmlFor="isLockerRequired">
                                          <span className="h4">Locker</span>
                                        </Label>
                                        <Field
                                          type="checkbox"
                                          id="isLockerRequired"
                                          name="isLockerRequired"
                                          className="form-check-input mt-2"
                                        />
                                      </FormGroup>
                                    </Col>
                                   
                                  </Row>
          </CardBody>
          </Card>
        
          <Card className="newCard">
          <CardBody>
          <h5 className="card-title text-upper">applicant info</h5>
                                   
                                <Row>
                                <div className="accordion" id="accordion">
                                <FieldArray name="createProjectLeadWorkSheetPurchaserList">
            {({ insert, remove, push }) => (
              <>
                {values.createProjectLeadWorkSheetPurchaserList.map((purchaser, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className="accordion-button fw-medium"
                        type="button"
                        onClick={() => this.toggleAccordion(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        Purchaser {index + 1}
                      </button>
                    </h2>

                    <Collapse isOpen={this.state.accordionState[`col${index}`]}  className="accordion-collapse">
                      <div className="accordion-body">
                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.firstName`}>
                          {this.props.t("First Name")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.firstName`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.firstName && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.firstName
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.firstName`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.lastName`}>
                          {this.props.t("Last Name")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.lastName`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.lastName && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.lastName
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.lastName`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.address`}>
                          {this.props.t("Address")}
                        </Label>
                            
                          <PlacesAutocomplete
                            value={purchaser.address}
                              onChange={(value) => setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.address`, value)}
                              onSelect={(value) => this.handlePlaceSelected(value, index, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                         
                                        <ErrorMessage
                                        name={`createProjectLeadWorkSheetPurchaserList.${index}.address`}
                                        component="div"
                                        className="invalid-feedback"
                                        />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.address`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.city`}>
                          {this.props.t("City")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.city`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.city && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.city
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.city`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.province`}>
                          {this.props.t("Province")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.province`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.province && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.province
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.province`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.postalCode`}>
                          {this.props.t("Postal Code")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.postalCode`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.postalCode && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.postalCode
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.postalCode`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.country`}>
                          {this.props.t("Country")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.country`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.country && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.country
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.country`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.honePhone`}>
                          {this.props.t("Home Phone")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.homePhone`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.homePhone && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.homePhone
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.homePhone`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.cellPhone`}>
                          {this.props.t("Cell Phone")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.cellPhone`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.cellPhone && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.cellPhone
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.cellPhone`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                            
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.email`}>
                          {this.props.t("Email")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.email`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.email && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.email
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`purchasers.${index}.email`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.birthDate`}>
                          {this.props.t("Birth Date")}
                        </Label>
                            <Flatpickr
                                  className={`form-control${
                                    errors.createProjectLeadWorkSheetPurchaserList?.[index]?.birthDate &&
                                    touched.createProjectLeadWorkSheetPurchaserList?.[index]?.birthDate
                                      ? ' is-invalid'
                                      : ''
                                  }`}
                                    placeholder=""
                                   
                                    onChange={(value) =>
                                      setFieldValue(`createProjectLeadWorkSheetPurchaserList.${index}.birthDate`, value[0])
                                    }
                                    options={{
                                      altInput: false,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                              
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.birthDate`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.driversLicense`}>
                          {this.props.t("Drivers License")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.driversLicense`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.driversLicense && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.driversLicense
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.driversLicense`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.occupation`}>
                          {this.props.t("Occupation")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.occupation`}
                                type="text"
                                className={
                                  'form-control' +
                                  (errors.createProjectLeadWorkSheetPurchaserList?.[index]?.occupation && touched.createProjectLeadWorkSheetPurchaserList?.[index]?.occupation
                                    ? ' is-invalid'
                                    : '')
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.occupation`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FieldArray name={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList`}>
                  {(arrayHelpers) => (
                    <div>
                      <h5>Upload</h5>
                      {purchaser.createProjectLeadWorkSheetPurchaserDocumentList.map((doc, docIndex) => (
                        <Row key={docIndex}>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                            <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentTitle`}>
                          {this.props.t("Document Name")}
                        </Label>
                              <Field
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentTitle`}
                                type="text"
                                className={
                                  "form-control" +
                                  (errors[`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentTitle`] &&
                                  touched[`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentTitle`]
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.documentTitle`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="5">
                            <FormGroup className="mb-3" id="upload-box">
                              <div className="form-group mb-3">
                              <Label htmlFor={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.document`}>
                          {this.props.t("Upload Document")}
                        </Label>
                              
                                <input type="file" name={`createProjectLeadWorkSheetPurchaserList.${index}.createProjectLeadWorkSheetPurchaserDocumentList.${docIndex}.document`} 
                                className="form-control"
                                onChange={event => this.handleFileChange(event, index, docIndex, setFieldValue)}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm="2">
                            <button type="button" className="btn btn-add" style={{marginTop:30}} onClick={() => arrayHelpers.remove(docIndex)}>
                              Remove
                            </button>
                          </Col>
                        </Row>
                      ))}
                      <button type="button" className="btn btn-add" onClick={() => arrayHelpers.push({ fileName: '', file: '' })}>
                        Add Document
                      </button>
                    </div>
                  )}
                </FieldArray>
                      </div>
                    </Collapse>
                  </div>
                ))}
              </>
            )}
          </FieldArray>
                      
                       
                      </div>
                                </Row>
                                {this.state.projectdata && (
                             <Row>
                             <Col sm="12" style={{marginTop:20}}>
                                <h6>By clicking submit below, yo consent to allow us from time to time to contact you about our products and services you requested, as well as other content that may be of interest to you</h6>
                              </Col>
                             <Col sm="12">
                                  <hr></hr>
                                </Col>

                                <Col sm="12">
                                  <button
                                    type="submit"
                                    className="btn btn-add w-md  mt-27"
                                    
                                  >
                                    Submit
                                  </button>
                                </Col>
                             </Row>
                                )}
                            </CardBody>
                          </Card>
                        </Form>
                      )}
                    </Formik>
                   ) : (
                    <div className="text-center">
                      <h4 className="text-danger">Thank you </h4>
                   
                   <h5>Thank you for submitting the form. </h5>
                   <h5>
                      {thankYouMessage}
                   </h5>
                    </div>
                  )}
                  </Col>
               )}  
                </Row>
              </TabPane>
          
            </TabContent>
          </Container>
        </div>
        </>
      ):(
        <>
           <div className="page-content">
           <Container fluid>
            <Row>
                <Col sm="3"></Col>
                <Col sm="6">
                    <Alert color="warning" className="text-center"><h5>Sorry no project found</h5></Alert>
                </Col>
            </Row>
           </Container>
          </div>
        </>
      )}
      </React.Fragment>
    );
  }
}
ProjectWorksheet.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history:PropTypes.object
};
export default connect()(withTranslation()(ProjectWorksheet));

